body {
  height: 100vh;
  background-color: white;
  color: black;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;

}

.menuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.text {
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 14px;
}

.status {
  width: 6px;
  height: 6px;
  border-radius: 100px
}

.blindsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px; 
  border-width: 0px 0px 1px 0px;
  border-color: white;
  border-style: solid;
  
}