.sceneContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(146, 158, 175, 0.15);
  border-radius: 10px;
  flex: 1;
  margin: 5px 0px;
  
}

.sceneContainer:hover{
  background:rgba(70, 171, 213, 1);
  transition: background-color .5s;
}

.lightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(146, 158, 175, 0.15);
  border-radius: 10px;
  flex: 1;
  margin: 40px 0px 0px 0px;
}

.buttonCurtains {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #ECF7FB;
  border-radius: 5px;
  flex: 1;
  margin: 0px 3.5px;
  border: none;
}

.upMenu {
  display: flex;
  background: #46ABD5;
  align-items: center;
  justify-content: space-between;
  padding: 14px 21px;
}

.tempContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(146, 158, 175, 0.15);
  border-radius: 10px;
  flex: 1;
}

.pultContainer {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(146, 158, 175, 0.25);
  width: 200px;
  height: 200px;
  border-radius: 200px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.homeContainer {
  display: 'flex';
  padding: 20px;
  flex: 1;
  flex-direction: column;
}