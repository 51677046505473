body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'NunitoSans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  font-family: 'NunitoSans';
}

@font-face {
  font-family: "NunitoSans";
  font-display: swap;
  font-style: normal;
  font-weight: 100 1000;
  src: local("NunitoSans"),
   url("./assets/fonts/NunitoSans.ttf") format("truetype");
 }
